.viewReactionContainer {
  display: flex;
  background-color: #1E1E1E;
  color: white;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
}
.reactionContainer {
  display: flex;
  background-color: #1E1E1E;
  color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.permissionModal {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 10px;
  color: 'white';
  background-image: url(../../images/permissions.svg);
  background-repeat:no-repeat;
  background-size: contain;
  background-position: bottom;
}
.permissionButton {
  border: 0;
  box-shadow: none;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  font-size: 1.2rem;
  font-weight: 600;
  background: #C9FF58;
  color: #000000;
}
.permissionButton:hover {
  background: #B5E64F
}
.permissionDenied {
  border: 0;
  box-shadow: none;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  font-size: .8rem;
  font-weight: 600;
  background: #C9FF58;
  color: #000000;
}
.videoContainers {
  position: relative;
  max-width: 500px;
  width: 90%;
  height: 80%;
  /* overflow: hidden; */
}
.clipContainer {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 32px;
}
.viewReactionVideoContainer {
  border-radius: 32px;
  max-width: calc(100vw - 32px);
}
.playButtonDisabled {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  opacity: 0.2;
}
.playButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}
.playButton svg:hover {
  transform: scale(1.1);
}
.progressGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.reactionPreview {
  position: fixed;
  bottom: 16px;
  right: 16px;
  border-radius: 30;
  z-index: 1;
}
.reactionPreview video {
  height: 200px;
  width: 120px;
}
.viewReactionPage {
  background-color: #1E1E1E;
  text-align: center;
}
.viewReactionPlayButtonContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.viewReactionPlayButtonContainer svg:hover {
  transform: scale(1.1);
}
.recordingIndicator {
  position: absolute;
  right: 10px;
  top: 10px;
}
.videoPlayer {
    width: 100%;
    height: 100%;
}
.hidePlayer {
    display: none;
}
